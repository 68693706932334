import React from "react";
import { Helmet } from "react-helmet";
import * as styles from "./style.module.scss";

import { FontMixinUI, TypographyMixinUI, TypographyUI } from "@ui";
import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { Fancybox, Layout } from "@components";
import selfworkBlueImg from "@images/legal/selfwork-blue.png";
import selfworkBlackImg from "@images/legal/selfwork-black.png";
import fnsImg from "@images/legal/fns.png";
import bankRussiaImg from "@images/legal/bank-of-russia.png";
import afiImg from "@images/legal/finance-innovation.png";
import pciDssImg from "@images/legal/pci-dss.png";
// import skolkovoImg from "@images/legal/skolkovo.png";
import certPciDss from "@images/legal/cert-pci-dss.jpg";
import reestrMinCifriImg from "@images/legal/reestr-mincifri.png"
import styled from "styled-components";
import { Typography } from "@mui/material";

// const rknLink =
//   "https://pd.rkn.gov.ru/operators-registry/operators-list/?id=77-19-015456";
const policyLink = "/legal/privacypolicy.pdf";
const swWalletAgreementLink = "/legal/walletterms.pdf";
const swWalletOfferLink = "/legal/walletoffer.pdf";
const swWalletRatesLink  = "/legal/wallet_rates.pdf";
const rulesCommunicationCustomerExecutorLink = "/legal/rules-communication-customer-executor.pdf";
const swMarketAgreementLink = "/legal/marketterms.pdf";
const forbiddenLink = "/legal/forbidden.pdf";
const fnsPartnerLink = "https://npd.nalog.ru/aggregators/";
const cbrLink = "https://cbr.ru/PSystem/registers/";
const afiLink = "http://afii.ru/news/novyj-uchastnik-nashej-assotsiatsii/";
// const skolkovoLink = "https://navigator.sk.ru/orn/1123452";
const reestrMinCifriLink = "https://reestr.digital.gov.ru/reestr/1149365/?sphrase_id=2318321\n";

const LegalPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Информация для самозанятых | Сайт с информацией для самозанятых</title>
      <meta name="description" content="В поиске информации для самозанятых? Наш сайт поможет вам с этим. Работа со всеми картами. Нет скрытых платежей" />
      <link rel="canonical" href="https://xn--80aapgyievp4gwb.xn--p1ai/legal" />
    </Helmet>
    <Layout>
      <TypographyUI.H1
        swichPoint={LAPTOP_MOBILE_POINTER}
        className={styles.title}
      >
        Юридическая информация
      </TypographyUI.H1>

      <div className={styles.cardContainer}>
        <div className={styles.cardTitle}>
          <TypographyUI.H3>Документы</TypographyUI.H3>
        </div>
        <div className={styles.cards}>

          <a className={styles.cardItem} target="_blank" href={policyLink}>
            <div className={styles.cardText}>
              <SubtitleSd>
                Политика в отношении обработки персональных данных
              </SubtitleSd>
            </div>
          </a>

          <a className={styles.cardItem} target="_blank" href={swWalletAgreementLink}>
            <div className={styles.cardText}>
              <SubtitleSd>
                Пользовательское соглашение сервиса Сам.Кошелёк
              </SubtitleSd>
            </div>
            <div className={styles.cardContent}>
              <img src={selfworkBlackImg} className={styles.cardImg} alt="selfwork-black"/>
            </div>
          </a>

          <a className={styles.cardItem} target="_blank" href={swWalletOfferLink}>
            <div className={styles.cardText}>
              <SubtitleSd>
                Публичная оферта на оказание услуг по осуществлению переводов
              </SubtitleSd>
            </div>
            <div className={styles.cardContent}>
              <img src={selfworkBlackImg} className={styles.cardImg} alt="selfwork-black"/>
            </div>
          </a>

          <a className={styles.cardItem} target="_blank" href={swWalletRatesLink}>
            <div className={styles.cardText}>
              <SubtitleSd>
                Тарифы по осуществлению переводов с использованием сервиса «Кошелек»
              </SubtitleSd>
            </div>
            <div className={styles.cardContent}>
              <img src={selfworkBlackImg} className={styles.cardImg} alt="selfwork-black"/>
            </div>
          </a>

          <a className={styles.cardItem} target="_blank" href={rulesCommunicationCustomerExecutorLink}>
            <div className={styles.cardText}>
              <SubtitleSd>
                Правила взаимодействия Заказчика и Исполнителя
              </SubtitleSd>
            </div>
          </a>

          <a className={styles.cardItem} target="_blank" href={fnsPartnerLink}>
            <div className={styles.cardText}>
              <SubtitleSd>
                Самозанятые.рф официальный партнер ФНС России
              </SubtitleSd>
            </div>
            <div className={styles.cardContent}>
              <img src={fnsImg} className={styles.cardImg} alt="fns" />
            </div>
          </a>

          <a className={styles.cardItem} target="_blank" href={cbrLink}>
            <div className={styles.cardText}>
              <SubtitleSd>
                Оператор услуг информационного обмена
              </SubtitleSd>
            </div>
            <div className={styles.cardContent}>
              <img
                src={bankRussiaImg}
                className={styles.cardImg}
                alt="bank-russia"
              />
            </div>
          </a>

          <a
            className={styles.cardItem}
            target="_blank"
            href={swMarketAgreementLink}
          >
            <div className={styles.cardText}>
              <SubtitleSd>
                Пользовательское соглашение сервиса Сам.Маркет
              </SubtitleSd>
            </div>
            <div className={styles.cardContent}>
              <img
                src={selfworkBlueImg}
                className={styles.cardImg}
                alt="selfwork-blue"
              />
            </div>
          </a>

          <a
              className={styles.cardItem}
              target="_blank"
              href={forbiddenLink}
          >
            <div className={styles.cardText}>
              <SubtitleSd>
                Список запрещенных товаров и услуг
              </SubtitleSd>
            </div>
            <div className={styles.cardContent}>
            </div>
          </a>
        </div>

        <div className={styles.cardTitle}>
          <TypographyUI.H3>Ассоциации и сертификаты</TypographyUI.H3>
        </div>

        <div className={styles.cards}>
          <a className={styles.cardItem} target="_blank" href={afiLink}>
            <div className={styles.cardText}>
              <SubtitleSd>
                Член Ассоциации Финансовые Инновации (АФИ)
              </SubtitleSd>
            </div>
            <div className={styles.cardContent}>
              <img src={afiImg} className={styles.cardImg} alt="afi" />
            </div>
          </a>

          {/*<Fancybox>*/}
            <div
              className={styles.cardItem}
              // data-fancybox="gallery"
              // data-src={certPciDss}
            >
              <div className={styles.cardText}>
                <SubtitleSd>
                  Сертификат соответствия PCI DSS
                </SubtitleSd>
              </div>
              <div className={styles.cardContent}>
                <img src={pciDssImg} className={styles.cardImg} alt="pci-dss" />
              </div>
            </div>
          {/*</Fancybox>*/}

          {/* <a className={styles.cardItem} target="_blank" href={skolkovoLink}>
            <div className={styles.cardText}>
              <SubtitleSd>
                Резидент Сколково
              </SubtitleSd>
            </div>
            <div className={styles.cardContent}>
              <img
                src={skolkovoImg}
                className={styles.cardImg}
                alt="skolkovo"
              />
            </div>
          </a> */}

          <a className={styles.cardItem} target="_blank" href={reestrMinCifriLink}>
            <div className={styles.cardText}>
              <SubtitleSd>
                Маркетплейс зарегистрирован в реестре Российского ПО
              </SubtitleSd>
            </div>
            <div className={styles.cardContent}>
              <img
                  src={reestrMinCifriImg}
                  className={styles.cardImg}
                  alt="reestr-mincifri"
              />
            </div>
          </a>
        </div>
      </div>
    </Layout>
  </>
);

export default LegalPage;

const SubtitleSd = styled.h3`
  ${FontMixinUI.CirceNormal}
  color: var(--text-primary);
  @media (min-width: 1205px) {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.3px;
  }
  @media (max-width: 1204px) {
    font-size: 17px;
    line-height: 24px;
  }
`;
